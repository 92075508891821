// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(x) {
  return Belt_Array.reduce(x, { }, (function (messages, message) {
                messages[message.id] = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(message.message), message.defaultMessage);
                return messages;
              }));
}

exports.make = make;
/* No side effect */
