/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react');
const sbjs = require('sourcebuster');

const Shell = require('./src/templates/Shell');

exports.wrapRootElement = ({ element }) => <Shell>{element}</Shell>;

exports.onClientEntry = () => {
  sbjs.init();
};
