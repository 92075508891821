const React = require('react');
const { IntlProvider } = require('react-intl');

const locale = require('reason/models/Locale.bs.js');
const translation = require('reason/i18n/Translation.bs.js');
const hcEn = require('reason/i18n/translations/listings-show.en.json');
const vrsEn = require('../translations/en.json');

module.exports = ({ children }) => (
  <IntlProvider locale={locale.toString(locale.default)} messages={translation.make({ ...hcEn, ...vrsEn })}>
    {children}
  </IntlProvider>
);
