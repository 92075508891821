// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(param) {
  return "en";
}

var all = [/* En */0];

var $$default = /* En */0;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.toString = toString;
exports.all = all;
/* No side effect */
